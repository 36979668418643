import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '../../components/Menu/Menu';
import Loader from '../../components/Loader/Loader';
import renderHTML from 'react-render-html';
import BackgorundVideo from "../Video/background_video2.mp4";

function NotFound() {
  const { t } = useTranslation();

  return (
    <>
    <Menu />
    <div className="Index hero">
      <img src="/img/logos/MCT_sustainable_white.svg" alt="MCT Logo" width="220" />
      <h1 className="hero__title">{t('notFoundPage.title')}</h1>
      <p className="hero__description">
      {renderHTML(t('notFoundPage.description'))}
      </p>
        <video autoPlay muted loop id="video">
          <source src={BackgorundVideo} type="video/mp4" />
        </video>
    </div>
    </>
  );
}

function HoF() {
  return (
    <Suspense fallback={<Loader open={true} />}>
      <NotFound />
    </Suspense>
  );
}

export default HoF;
