import React, { Suspense, useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import "./Foundation.scss";
import Menu from "../../components/Menu/Menu";
import Address from "../../components/Address/Address";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import BackgorundVideo from "../Video/background_video2.mp4";

function Foundation() {
  const { t } = useTranslation();
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    fetch("/data/certificates.json")
      .then((res) => res.json())
      .then((jsonData) => {
        setCertificates(jsonData);
      });
  }, []);

  return (
    <>
      <Menu />
      <div className="hero hero--thin">
        <img
          src="/img/logos/MCT_sustainable_white.svg"
          alt="MCT Foundation"
          className="logo-foundation"
        />
        <video autoPlay muted loop id="video">
          <source src={BackgorundVideo} type="video/mp4" />
        </video>
      </div>

      <div className="main text-sections">
        <div className="container">
          <div className="col">
            <h2 className="title title--green title--left">
              {t("foundationPage.missionStatementTitle")}
            </h2>
            <p>{t("foundationPage.missionStatementText1")}</p>
            <p>{t("foundationPage.missionStatementText2")}</p>

            <div className="anchor" data-id="donate"></div>
            <h2 className="title title--green title--left">
              {t("foundationPage.technicalSupportTitle")}
            </h2>
            <p>{t("foundationPage.technicalSupportText")}</p>
            <p>
              <Trans i18nKey="foundationPage.generalFundPpcAddress">
                General Fund - <strong>PPC</strong> Donation Address:
              </Trans>
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="https://chainz.cryptoid.info/ppc/address.dws??.htm"
              >
                {" "}
                ?
              </a>
              <br />
              <Trans i18nKey="foundationPage.generalFundBtcAddress">
                General Fund - <strong>BTC</strong> Donation Address
              </Trans>
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="https://mempool.space/address/?"
              >
                {" "}
                ?
              </a>
              <br />
              <Trans i18nKey="foundationPage.generalFundEthAddress">
                General Fund - <strong>ETH/USDC/WBTC</strong> Donation Address
              </Trans>
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="https://etherscan.io/address/?"
              >
                {" "}
                ?
              </a>
            </p>
            {/* <div>
              <h2 className="title title--green title--left">
                {t("foundationPage.reportsTitle")}
              </h2>
              <p>{t("foundationPage.reportsSupportText")}</p>
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/assets/reports/Micro_credit_token_foundation_financialReport_2023.pdf"
              >
                Financial Report 2023
              </a>
            </div> */}
          </div>
          <div className="col col--small">
            <Address />
            <p>
              <a href="mailto:info@microcredittoken.com">
                info@microcredittoken.com
              </a>
            </p>
          </div>
          <div className="main">
            <div className="anchor" data-id="certificates"></div>
            <div className="container">
              <h2 className="title title--green">{t(certificates.title)}</h2>
              <div className="blocks-list">
                {certificates.resources &&
                  certificates.resources.map((certificates) => (
                    <a
                      key={certificates.title}
                      href="/foundation"
                      className="blocks-list__block"
                    >
                      <h4 className="blocks-list__block__title">
                        {t(certificates.title)}
                      </h4>
                      <img
                        className="blocks-list__block__img"
                        src={certificates.image}
                        alt={certificates.title}
                      />
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

function HoF() {
  return (
    <Suspense fallback={<Loader open={true} />}>
      <Foundation />
    </Suspense>
  );
}

export default HoF;
