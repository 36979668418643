import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { useTranslation } from "react-i18next";
import "./Footer.scss";
import Loader from "../Loader/Loader";

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="main main--grey main--no_bottom">
      <div className="footer-wrapper">
        <div className="footer-wrapper__dark_bg"></div>
        <div className="container">
          <div className="footer-wrapper__subscribe">
            <h2 className="title title--green">
              {t("footerComponent.subscribeTitle")}
            </h2>
            <p>{t("footerComponent.subscribeSubtitle")}</p>
            <a
              href="#"
              rel="noopener noreferrer nofollow"
              className="footer-wrapper__subscribe__link"
            >
              {t("footerComponent.subscribeBtnLabel")}
            </a>
          </div>
          <footer className="footer">
            <div className="footer__twitter">
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="microcreditoken"
                options={{ width: 265, height: 350 }}
              />
            </div>

            <nav className="footer__links">
              <ul className="footer__links__list">
                <li className="footer__links__list__title">
                  {t("footerComponent.links.learn.title")}
                </li>
                <li>
                  <Link to="/projects">
                    {t(
                      "footerComponent.links.learn.linkInherentCentralization"
                    )}
                  </Link>
                </li>
                <li>
                  <Link to="/projects">
                    {t(
                      "footerComponent.links.learn.linkMCTProofOfStakeConsensus"
                    )}
                  </Link>
                </li>
                <li>
                  <Link to="/projects">
                    {t("footerComponent.links.learn.linkEconomicsOfMCT")}
                  </Link>
                </li>
                <li>
                  <Link to="/projects">
                    {t("footerComponent.links.learn.linkScalabilityOfMCT")}
                  </Link>
                </li>
              </ul>
              <ul className="footer__links__list">
                <li className="footer__links__list__title">
                  <a href="/#getstarted">
                    {t("footerComponent.links.getStarted.title")}
                  </a>
                </li>
                <li className="footer__links__list__title">
                  <Link to="/resources">
                    {t("footerComponent.links.resources.title")}
                  </Link>
                </li>
                <li>
                  <Link to="/foundation">
                    {t("footerComponent.links.resources.linkDocumentation")}
                  </Link>
                </li>
                <li>
                  <Link to="/resources">
                    {t("footerComponent.links.resources.linkExchanges")}
                  </Link>
                </li>
                <li>
                  <Link to="/resources">
                    {t("footerComponent.links.resources.linkBlockExplorers")}
                  </Link>
                </li>
                <li>
                  <Link to="/resources">
                    {t("footerComponent.links.resources.linkWhitepaper")}
                  </Link>
                </li>
                <li>
                  <Link to="/resources">
                    {t("footerComponent.links.resources.linkGraphics")}
                  </Link>
                </li>
              </ul>
              <ul className="footer__links__list">
                <li className="footer__links__list__title">
                  {t("footerComponent.links.project.title")}
                </li>
                <li>
                  <a
                    href="https://micro-credit-ecosystem.gitbook.io/micro-credit-ecosystem-eng/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {t("footerComponent.links.project.linkGithub")}
                  </a>
                </li>
                <li>
                  <Link to="/projects">
                    {t("footerComponent.links.project.linkProjects")}
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    {t("footerComponent.links.project.linkContact")}
                  </Link>
                </li>
                <li>
                  <Link to="/privacy">
                    {t("footerComponent.links.project.linkPrivacyPolicy")}
                  </Link>
                </li>
              </ul>
              <ul className="footer__links__list">
                <li className="footer__links__list__title">
                  <a href="/resources#communities">
                    {t("footerComponent.links.community.title")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/microcredittoken"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {t("footerComponent.links.community.linkTwitter")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/mctcommunitychanneleng"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {t("footerComponent.links.community.linkTelegram")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://microcreditoken.medium.com/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {t("footerComponent.links.community.linkMedium")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/microcreditoken/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {t("footerComponent.links.community.linkInstagram")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/microcreditoken"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {t("footerComponent.links.community.linkFacebook")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://youtu.be/8yb1DUsE5_c"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {t("footerComponent.links.community.linkYoutube")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://coinmarketcap.com/currencies/microcredittoken/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {t("footerComponent.links.community.linkCmc")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.coingecko.com/en/coins/microcredittoken"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {t("footerComponent.links.community.linkGecko")}
                  </a>
                </li>
              </ul>
            </nav>
          </footer>
        </div>
        <div className="footer-wrapper__copyright">
          {t("footerComponent.copyright.copyright")} <br />
          {t("footerComponent.copyright.rights")}
        </div>
      </div>
    </div>
  );
}

function HoF() {
  return (
    <Suspense fallback={<Loader open={true} />}>
      <Footer />
    </Suspense>
  );
}

export default HoF;
