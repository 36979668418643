import React from 'react';
import './Modal.scss';

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        <div className="modal-icon">
          <span>!</span>
        </div>
        <h2 className="modal-title">Attention</h2>
        <p className="modal-message">
          Buying and selling of crypto assets is not available through our website. 
          Please do not trust any individuals or organizations providing such information.
        </p>
        <button className="modal-button" onClick={onClose}>Okay</button>
      </div>
    </div>
  );
};

export default Modal;
