import React, { useEffect, useState, Suspense } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";
import "./Menu.scss";
import { getCurrentFlag } from "../../helpers/Language";

function Menu() {
  const { t } = useTranslation();
  const triggerHeight = 100;
  const [isFixed, setIsFixed] = useState(window.scrollY > triggerHeight);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > triggerHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    });

    if (window.scrollY > triggerHeight) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  }, []);

  async function toggleMenu() {
    await setIsOpen(!isOpen);
  }

  function menuClass() {
    return classNames({
      menu: true,
      "menu--fixed": isFixed,
      "menu--open": isOpen,
    });
  }

  return (
    <>
      <div className="menu-trigger" onClick={toggleMenu}>
        <img src="/img/menu.png" alt="" />
      </div>
      <nav className={menuClass()}>
        <div className="menu__close" onClick={toggleMenu}>
          <img src="/img/back.png" alt="" />
        </div>
        <div className="container">
          {isFixed && (
            <Link to="/">
              <img
                src="/img/logo-menu-white.svg"
                alt="MCT"
                className="menu__logo"
              />
            </Link>
          )}
          <ul className="menu__main">
            <li>
              <Link to="/">{t("menuComponent.links.home.title")}</Link>
            </li>
            {/* <li>
              <a href="/#getstarted">
                {t("menuComponent.links.getStarted.title")}
              </a>
            </li> */}
            <li>
              <Link to="/foundation">
                {t("menuComponent.links.resources.documentation")}
              </Link>
            </li>
            <li>
              <Link to="/resources">
                {t("menuComponent.links.resources.title")}
              </Link>
              {/* <img src="/img/icon-menu-arrow-down.svg" alt="" />
              <ul className="menu__main__submenu">
                <li>
                  <a
                    href="/foundation"
                    className="submenu"
                    rel="noopener noreferrer"
                  >
                    <img src="/img/icons/docs_blue.png" alt="" />
                    {t("menuComponent.links.resources.documentation")}
                  </a>
                </li>
                <li>
                  <a href="/resources#exchanges" className="submenu">
                    <img src="/img/icons/exchange_blue.png" alt="" />
                    {t("menuComponent.links.resources.exchanges")}
                  </a>
                </li>
                <li>
                  <a href="/resources#blockexplorers" className="submenu">
                    <img src="/img/icons/block_blue.png" alt="" />
                    {t("menuComponent.links.resources.blockExplorers")}
                  </a>
                </li>
                <li>
                  <a href="/resources#socials" className="submenu">
                    <img src="/img/icons/community_blue.png" alt="" />
                    {t("menuComponent.links.resources.community")}
                  </a>
                </li>
                <li>
                  <a href="/resources#whitepaper" className="submenu">
                    <img src="/img/icons/whitepaper_blue.png" alt="" />
                    {t("menuComponent.links.resources.whitepaper")}
                  </a>
                </li>
                <li>
                  <a href="/resources#graphics" className="submenu">
                    <img src="/img/icons/graphics_blue.png" alt="" />
                    {t("menuComponent.links.resources.graphics")}
                  </a>
                </li>
              </ul> */}
            </li>
            <li>
              <Link to="/partners">{t("menuComponent.links.partners.title")}</Link>
            </li>
            <li>
              <Link to="/projects">
                {t("menuComponent.links.project.title")}
              </Link>
              {/* <img src="/img/icon-menu-arrow-down.svg" alt="" />
              <ul className="menu__main__submenu">
                <li>
                  <a href="/projects#wallet&market" className="submenu">
                    <img src="/img/icons/wallet_blue.png" alt="" />
                    {t("menuComponent.links.project.github")}
                  </a>
                </li>
                <li>
                  <a href="/projects#wallet&market" className="submenu">
                    <img src="/img/icons/marketplace_blue.png" alt="" />
                    {t("menuComponent.links.project.blog")}
                  </a>
                </li>
                <li>
                  <a href="/projects#bot&universe" className="submenu">
                    <img src="/img/icons/metaverse_blue.png" alt="" />
                    {t("menuComponent.links.project.projects")}
                  </a>
                </li>
                <li>
                  <a href="/projects#bot&universe" className="submenu">
                    <img src="/img/icons/mint_blue.png" alt="" />
                    {t("menuComponent.links.project.futureBot")}
                  </a>
                </li>
                <li>
                  <a href="/contact" className="submenu">
                    <img src="/img/icons/contact_blue.png" alt="" />
                    {t("menuComponent.links.project.contactUs")}
                  </a>
                </li>
              </ul> */}
            </li>
            <li>
              <span>{t("menuComponent.links.community.title")}</span>
              <img src="/img/icon-menu-arrow-down.svg" alt="" />
              <ul className="menu__main__submenu">
                <li>
                  <a
                    href="https://twitter.com/microcredittoken"
                    className="submenu"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img src="/img/icons/twitter_blue.png" alt="" />
                    {t("menuComponent.links.community.twitter")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/mctcommunitychanneleng"
                    className="submenu"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img src="/img/icons/telegram_blue.png" alt="" />
                    {t("menuComponent.links.community.telegram")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/microcreditoken/"
                    className="submenu"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img src="/img/icons/instagram_blue.png" alt="" />
                    {t("menuComponent.links.community.instagram")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://microcreditoken.medium.com/"
                    className="submenu"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img src="/img/icons/medium_blue.png" alt="" />
                    {t("menuComponent.links.community.medium")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/microcreditoken"
                    className="submenu"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img src="/img/icons/facebook_blue.png" alt="" />
                    {t("menuComponent.links.community.facebook")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://youtu.be/8yb1DUsE5_c"
                    className="submenu"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img src="/img/icons/youtube_blue.png" alt="" />
                    {t("menuComponent.links.community.youtube")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://coinmarketcap.com/currencies/microcredittoken/"
                    className="submenu"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img src="/img/icons/cmc_blue.png" alt="" />
                    {t("menuComponent.links.community.cmc")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.coingecko.com/en/coins/microcredittoken"
                    className="submenu"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img src="/img/icons/gecko_blue.png" alt="" />
                    {t("menuComponent.links.community.gecko")}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <span>
                <img
                  className="menu-flag"
                  src={`/img/flags/${getCurrentFlag()}.svg`}
                  alt="tr"
                />
              </span>
              <img src="/img/icon-menu-arrow-down.svg" alt="" />
              <ul className="menu__main__submenu menu__main__submenu--small">
                {/*<li onClick={() => i18n.changeLanguage("tr")}>
                  <img className="menu-flag" src="img/flags/TR.svg" alt="tr" />
                </li> */}
                <li onClick={() => i18n.changeLanguage("en-US")}>
                  <img
                    className="menu-flag"
                    src="img/flags/US.svg"
                    alt="en-US"
                  />
                </li>
                {/* <li onClick={() => i18n.changeLanguage("de")}>
                  <img className="menu-flag" src="img/flags/DE.svg" alt="de" />
                </li>
                <li onClick={() => i18n.changeLanguage("es")}>
                  <img className="menu-flag" src="img/flags/ES.svg" alt="es" />
                </li>
                <li onClick={() => i18n.changeLanguage("fr")}>
                  <img className="menu-flag" src="img/flags/FR.svg" alt="fr" />
                </li>
                <li onClick={() => i18n.changeLanguage("ko")}>
                  <img className="menu-flag" src="img/flags/KO.svg" alt="ko" />
                </li>
                <li onClick={() => i18n.changeLanguage("pt")}>
                  <img className="menu-flag" src="img/flags/PT.svg" alt="pt" />
                </li>
                <li onClick={() => i18n.changeLanguage("pl")}>
                  <img className="menu-flag" src="img/flags/PL.svg" alt="pl" />
                </li>
                <li onClick={() => i18n.changeLanguage("ro")}>
                  <img className="menu-flag" src="img/flags/RO.svg" alt="ro" />
                </li>
                <li onClick={() => i18n.changeLanguage("ru")}>
                  <img className="menu-flag" src="img/flags/RU.svg" alt="ro" />
                </li> */}
                {/* <li onClick={() => i18n.changeLanguage('pt- BR')}><img className="menu-flag" src="img/flags/BR.svg" alt="pt-BR"/></li> */}
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

function HoF() {
  return (
    <Suspense fallback={<Loader open={true} />}>
      <Menu />
    </Suspense>
  );
}

export default HoF;
