import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import "./Partners.scss";
import renderHTML from "react-render-html";
import Menu from "../../components/Menu/Menu";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import BackgorundVideo from "../Video/background_video2.mp4";

function Partners() {
  const { t } = useTranslation();

  return (
    <>
      <Menu />
      <div className="hero hero--thin">
        <h1 className="hero__title">{renderHTML(t("partnersPage.title"))}</h1>
        <p className="hero__description">
        {t("partnersPage.description")}
        </p>
        <video autoPlay muted loop id="video">
          <source src={BackgorundVideo} type="video/mp4" />
        </video>
      </div>

      <div className="main text-sections">
        <div className="container">
          <div className="col">
            <h2 className="title title--green title--left">
              {t("partnersPage.headTitle")}
            </h2>
            <p>{t("partnersPage.headText1")}</p>
          </div>

          <section className="home-description">
            <img
              className="home-description__img2"
              src="/img/partners/tisva.png"
              alt=""
            />
            <div className="home-description__text home-description__text--left">
              <h2 className="home-description__text__title">
                {t("partnersPage.tisvaTitle")}
              </h2>
              <p>{renderHTML(t("partnersPage.tisvaText1"))}</p>
              <p>{renderHTML(t("partnersPage.tisvaText2"))}</p>
              <p>{renderHTML(t("partnersPage.tisvaText3"))}</p>
            </div>
          </section>

          <section className="home-description">
            <div className="home-description__text">
              <h2 className="home-description__text__title">
                {t("partnersPage.tgmpTitle")}
              </h2>
              <p>{renderHTML(t("partnersPage.tgmpText1"))}</p>
              <p>{renderHTML(t("partnersPage.tgmpText2"))}</p>
              <p>{renderHTML(t("partnersPage.tgmpText3"))}</p>
            </div>
            <img
              className="home-description__img2"
              src="/img/partners/tgmp.png"
              alt=""
            />
          </section>

          <section className="home-description">
            <img
              className="home-description__img2"
              src="/img/partners/care.png"
              alt=""
            />
            <div className="home-description__text home-description__text--left">
              <h2 className="home-description__text__title">
                {t("partnersPage.careTitle")}
              </h2>
              <p>{renderHTML(t("partnersPage.careText1"))}</p>
              <p>{renderHTML(t("partnersPage.careText2"))}</p>
              <p>{renderHTML(t("partnersPage.careText3"))}</p>
            </div>
          </section>

          <section className="home-description">
            <div className="home-description__text">
              <h2 className="home-description__text__title">
                {t("partnersPage.sfiTitle")}
              </h2>
              <p>{renderHTML(t("partnersPage.sfiText1"))}</p>
              <p>{renderHTML(t("partnersPage.sfiText2"))}</p>
              <p>{renderHTML(t("partnersPage.sfiText3"))}</p>
            </div>
            <img
              className="home-description__img2"
              src="/img/partners/sfi.png"
              alt=""
            />
          </section>

          <section className="home-description">
            <img
              className="home-description__img2"
              src="/img/partners/kiva.png"
              alt=""
            />
            <div className="home-description__text home-description__text--left">
              <h2 className="home-description__text__title">
                {t("partnersPage.kivaTitle")}
              </h2>
              <p>{renderHTML(t("partnersPage.kivaText1"))}</p>
              <p>{renderHTML(t("partnersPage.kivaText2"))}</p>
              <p>{renderHTML(t("partnersPage.kivaText3"))}</p>
            </div>
          </section>

          <section className="home-description">
            <div className="home-description__text">
              <h2 className="home-description__text__title">
                {t("partnersPage.trantorTitle")}
              </h2>
              <p>{renderHTML(t("partnersPage.trantorText1"))}</p>
              <p>{renderHTML(t("partnersPage.trantorText2"))}</p>
              <p>{renderHTML(t("partnersPage.trantorText3"))}</p>
            </div>
            <img
              className="home-description__img2"
              src="/img/partners/trantor.png"
              alt=""
            />
          </section>

          <section className="home-description">
            <img
              className="home-description__img2"
              src="/img/partners/lbank.png"
              alt=""
            />
            <div className="home-description__text home-description__text--left">
              <h2 className="home-description__text__title">
                {t("partnersPage.lbankTitle")}
              </h2>
              <p>{renderHTML(t("partnersPage.lbankText1"))}</p>
              <p>{renderHTML(t("partnersPage.lbankText2"))}</p>
              <p>{renderHTML(t("partnersPage.lbankText3"))}</p>
            </div>
          </section>

          <section className="home-description">
            <div className="home-description__text">
              <h2 className="home-description__text__title">
                {t("partnersPage.bscTitle")}
              </h2>
              <p>{renderHTML(t("partnersPage.bscText1"))}</p>
              <p>{renderHTML(t("partnersPage.bscText2"))}</p>
              <p>{renderHTML(t("partnersPage.bscText3"))}</p>
              <p>{renderHTML(t("partnersPage.bscText4"))}</p>
              <p>{renderHTML(t("partnersPage.bscText5"))}</p>
            </div>
            <img
              className="home-description__img2"
              src="/img/partners/bsc.png"
              alt=""
            />
          </section>

          <section className="home-description">
            <img
              className="home-description__img2"
              src="/img/partners/bnb.png"
              alt=""
            />
            <div className="home-description__text home-description__text--left">
              <h2 className="home-description__text__title">
                {t("partnersPage.bnbTitle")}
              </h2>
              <p>{renderHTML(t("partnersPage.bnbText1"))}</p>
              <p>{renderHTML(t("partnersPage.bnbText2"))}</p>
              <p>{renderHTML(t("partnersPage.bnbText3"))}</p>
            </div>
          </section>

          <section className="home-description">
            <div className="home-description__text">
              <h2 className="home-description__text__title">
                {t("partnersPage.unrealTitle")}
              </h2>
              <p>{renderHTML(t("partnersPage.unrealText1"))}</p>
              <p>{renderHTML(t("partnersPage.unrealText2"))}</p>
              <p>{renderHTML(t("partnersPage.unrealText3"))}</p>
            </div>
            <img
              className="home-description__img2"
              src="/img/partners/unreal.png"
              alt=""
            />
          </section>

          <section className="home-description">
            <img
              className="home-description__img2"
              src="/img/partners/gecko.png"
              alt=""
            />
            <div className="home-description__text home-description__text--left">
              <h2 className="home-description__text__title">
                {t("partnersPage.geckoTitle")}
              </h2>
              <p>{renderHTML(t("partnersPage.geckoText1"))}</p>
              <p>{renderHTML(t("partnersPage.geckoText2"))}</p>
              <p>{renderHTML(t("partnersPage.geckoText3"))}</p>
            </div>
          </section>

          <section className="home-description">
            <div className="home-description__text">
              <h2 className="home-description__text__title">
                {t("partnersPage.cmcTitle")}
              </h2>
              <p>{renderHTML(t("partnersPage.cmcText1"))}</p>
              <p>{renderHTML(t("partnersPage.cmcText2"))}</p>
              <p>{renderHTML(t("partnersPage.cmcText3"))}</p>
            </div>
            <img
              className="home-description__img2"
              src="/img/partners/cmc.png"
              alt=""
            />
          </section>

          
        </div>
      </div>
      <Footer />
    </>
  );
}

function HoF() {
  return (
    <Suspense fallback={<Loader open={true} />}>
      <Partners />
    </Suspense>
  );
}

export default HoF;
