import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import renderHTML from 'react-render-html';
import './Projects.scss';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Loader from '../../components/Loader/Loader';
import BackgorundVideo from "../Video/background_video2.mp4";

function Projects() {
  const { t } = useTranslation();

  return (
    <>
      <Menu />
      <div className="hero hero--thin">
        <h1 className="hero__title">{t('projectsPage.title')}</h1>
        <p className="hero__description">
        {t('projectsPage.description')}
        </p>
        <video autoPlay muted loop id="video">
          <source src={BackgorundVideo} type="video/mp4" />
        </video>
      </div>

      <div className="main text-sections" data-id="wallet&market">
        <div className="container">
          <div className="row">
            <div id='wallet' className="col">
              <h2 className="title title--green title--left">{t('projectsPage.mctWalletTitle')}</h2>
              <p>{t('projectsPage.mctWalletP1')}</p>
              <p>{t('projectsPage.mctWalletP2')}</p>
              <p>{t('projectsPage.mctWalletP3')}</p>
              <p><i>{renderHTML(t('projectsPage.mctWalletP4'))}</i></p>
              <a href="/projects#wallet&market" className="btn btn--green">{t('projectsPage.mctWalletBtnMobile')}</a>
              <a href="/projects#wallet&market" className="btn btn--green">{t('projectsPage.mctWalletBtnBrowser')}</a>
            </div>
            <div id='marketplace' className="col">
              <h2 className="title title--green title--left">{t('projectsPage.mctMarketTitle')}</h2>
              <p>{t('projectsPage.mctMarketP1')}</p>
              <p>{t('projectsPage.mctMarketP2')}</p>
              <p>{t('projectsPage.mctMarketP3')}</p>
              <p><i>{t('projectsPage.mctMarketP4')}</i></p>
              <a href="/projects#wallet&market" className="btn btn--green">{t('projectsPage.mctMarketBtn')}</a>
            </div>
          </div>
        </div>
      </div>
      <div className="main text-sections" data-id="bot&universe">
        <div className="container">
          <div className="row">
            <div className="col" id='futureBot'>
              <h2 className="title title--green title--left">{t('projectsPage.futureBotTitle')}</h2>
              <p>{t('projectsPage.futureBotP1')}</p>
              <p>{t('projectsPage.futureBotP2')}</p>
              <p>{t('projectsPage.futureBotP3')}</p>
              <p><i>{t('projectsPage.futureBotP4')}</i></p>
              <a href="/projects#bot&universe" className="btn btn--green">{t('projectsPage.futureBotBtn')}</a>
            </div>
            <div className="col" id='futureBot'>
              <h2 className="title title--green title--left">{t('projectsPage.universe')}</h2>
              <p>{t('projectsPage.universeP1')}</p>
              <p>{t('projectsPage.universeP2')}</p>
              <p>{t('projectsPage.universeP3')}</p>
              <p><i>{t('projectsPage.universeP4')}</i></p>
              <a href="/projects#bot&universe" className="btn btn--green">{t('projectsPage.universeBtn')}</a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

function HoF() {
  return (
    <Suspense fallback={<Loader open={true} />}>
      <Projects />
    </Suspense>
  );
}

export default HoF;
