import React, { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import Collapsible from "react-collapsible";
import renderHTML from "react-render-html";
import YouTube from "react-youtube";
import Modal from "../../components/Modal/Modal";
import "./Index.scss";
import Menu from "../../components/Menu/Menu";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import BackgorundVideo from "../Video/background_video2.mp4";

function Index() {
  const youtubeVideos = [
    "8yb1DUsE5_c",
    "yubzJw0uiE4",
    "-Hbsq2FAlXU",
    "-2w8MiwS9J8",
    "HQaegigv6jU",
  ];

  const { t } = useTranslation();
  const [currentVideo, setCurrentVideo] = useState(0);
  const [isVideoPristine, setIsVideoPristine] = useState(true);
  const [isModalOpen, setModalOpen] = useState(true);

  async function onYoutubeEnd() {
    if (currentVideo === youtubeVideos.length - 1) {
      return;
    }

    await setIsVideoPristine(false);
    await setCurrentVideo(currentVideo + 1);
  }

  async function setVideo(index) {
    await setIsVideoPristine(false);
    await setCurrentVideo(index);
  }

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Menu />
      <Modal isOpen={isModalOpen} onClose={closeModal}>
      </Modal>
      <div className="Index hero">
        <img
          className="hero__logo"
          src="/img/logos/MCT_sustainable_white.svg"
          alt="MCT Logo"
        />
        <div className="hero__actions">
          <a href="/#getstarted" className="hero__actions__action">
            {t("indexPage.actions.action1")}
          </a>
          <a
            href="https://micro-credit-ecosystem.gitbook.io/micro-credit-ecosystem-eng/"
            className="hero__actions__action hero__actions__action--white"
          >
            {t("indexPage.actions.action2")}
          </a>
        </div>
        <video autoPlay muted loop id="video">
          <source src={BackgorundVideo} type="video/mp4" />
        </video>
      </div>
      <div className="main">
        <div className="container">
          <section className="home-youtube">
            <h1 className="title title--green">1MCT Channel</h1>
            <p className="home-youtube__subtext">
              {t("indexPage.youtubeSection.subText")}
            </p>
            <YouTube
              opts={{
                playerVars: {
                  modestbranding: 1,
                  autoplay: isVideoPristine ? 0 : 1,
                },
              }}
              videoId={youtubeVideos[currentVideo]}
              className="home-youtube__video__player"
              containerClassName="home-youtube__video"
              onEnd={onYoutubeEnd}
            />
            <div className="video-buttons">
              <button
                className={currentVideo === 0 ? "btn btn--selected" : "btn"}
                onClick={() => setVideo(0)}
              >
                {t("indexPage.youtubeSection.btnVideo1")}
              </button>
              <button
                className={currentVideo === 1 ? "btn btn--selected" : "btn"}
                onClick={() => setVideo(1)}
              >
                {t("indexPage.youtubeSection.btnVideo2")}
              </button>
              <button
                className={currentVideo === 2 ? "btn btn--selected" : "btn"}
                onClick={() => setVideo(2)}
              >
                {t("indexPage.youtubeSection.btnVideo3")}
              </button>
              <button
                className={currentVideo === 3 ? "btn btn--selected" : "btn"}
                onClick={() => setVideo(3)}
              >
                {t("indexPage.youtubeSection.btnVideo4")}
              </button>
              <button
                className={currentVideo === 4 ? "btn btn--selected" : "btn"}
                onClick={() => setVideo(4)}
              >
                {t("indexPage.youtubeSection.btnVideo5")}
              </button>
            </div>
          </section>
          <h1 className="title title--green">{t("indexPage.whoWeAre")}</h1>

          <section className="home-description">
            <div className="home-description__text">
              <h2 className="home-description__text__title">
                {t("indexPage.efficientEnergyTitle")}
              </h2>
              <p>{renderHTML(t("indexPage.efficientEnergyText1"))}</p>
              <p>{renderHTML(t("indexPage.efficientEnergyText2"))}</p>
              <p>{renderHTML(t("indexPage.efficientEnergyText3"))}</p>
            </div>
            <img
              className="home-description__img"
              src="/img/animations/New_MCT-Logo.png"
              alt=""
            />
          </section>
          <section className="home-description">
            <img
              className="home-description__img"
              src="/img/animations/tisva.png"
              alt=""
            />
            <div className="home-description__text home-description__text--left">
              <h2 className="home-description__text__title">
                {t("indexPage.userGovernanceTitle")}
              </h2>
              <p>{renderHTML(t("indexPage.userGovernanceText1"))}</p>
              <p>{renderHTML(t("indexPage.userGovernanceText2"))}</p>
              <p>{renderHTML(t("indexPage.userGovernanceText3"))}</p>
            </div>
          </section>
          <section className="home-description">
            <div className="home-description__text">
              <h2 className="home-description__text__title">
                {t("indexPage.trustlessTitle")}
              </h2>
              <p>{renderHTML(t("indexPage.trustlessText1"))}</p>
              <p>{renderHTML(t("indexPage.trustlessText2"))}</p>
            </div>
            <img
              className="home-description__img"
              src="/img/animations/tgmp.png"
              alt=""
            />
          </section>
        </div>
        <div className="main main--green">
          <div className="anchor" data-id="getstarted"></div>
          <div className="container">
            <div className="timeline">
              <h2 className="timeline__title">
                {t("indexPage.getStartedTitle")}
              </h2>
              <div className="timeline__body">
                <Collapsible
                  open={true}
                  trigger={
                    <div className="timeline__body__section">
                      <img src="/img/icons/graduationcap_white.png" alt="" />{" "}
                      {t("indexPage.collapsables.learnTitle")}
                    </div>
                  }
                >
                  <div className="timeline__body__content">
                    <div className="timeline__body__content__text">
                      <p>
                        {renderHTML(t("indexPage.collapsables.learnText1"))}
                      </p>
                      <p>
                        {renderHTML(t("indexPage.collapsables.learnText2"))}
                      </p>
                      <p>
                        {renderHTML(t("indexPage.collapsables.learnText3"))}
                      </p>
                    </div>
                    <div className="timeline__body__content__img">
                      <img src="/img/icons/sidecard-learn.png" alt="" />
                    </div>
                  </div>
                </Collapsible>
                <Collapsible
                  open={true}
                  trigger={
                    <div className="timeline__body__section">
                      <img src="/img/icons/wallets.png" alt="" />{" "}
                      {t("indexPage.collapsables.walletTitle")}
                    </div>
                  }
                >
                  <div className="timeline__body__content">
                    <div className="timeline__body__content__text">
                      <p>
                        {renderHTML(t("indexPage.collapsables.walletText1"))}
                      </p>
                      <p>
                        {renderHTML(t("indexPage.collapsables.walletText2"))}
                      </p>
                      <p>
                        {renderHTML(t("indexPage.collapsables.walletText3"))}
                      </p>
                    </div>
                    <div className="timeline__body__content__img">
                      <img src="/img/icons/sidecard-wallet.png" alt="" />
                    </div>
                  </div>
                </Collapsible>
                <Collapsible
                  open={true}
                  trigger={
                    <div className="timeline__body__section">
                      <img src="/img/icons/metaverse.png" alt="" />{" "}
                      {t("indexPage.collapsables.purchaseTitle")}
                    </div>
                  }
                >
                  <div className="timeline__body__content">
                    <div className="timeline__body__content__text">
                      <p>
                        {renderHTML(t("indexPage.collapsables.purchaseText1"))}
                      </p>
                      <p>
                        {renderHTML(t("indexPage.collapsables.purchaseText2"))}
                      </p>
                      <p>
                        {renderHTML(t("indexPage.collapsables.purchaseText3"))}
                      </p>
                    </div>
                    <div className="timeline__body__content__img">
                      <img src="/img/icons/sidecard-metaverse.png" alt="" />
                    </div>
                  </div>
                </Collapsible>
                <Collapsible
                  open={true}
                  trigger={
                    <div className="timeline__body__section">
                      <img src="/img/icons/marketplace.png" alt="" />{" "}
                      {t("indexPage.collapsables.mintTitle")}
                    </div>
                  }
                >
                  <div className="timeline__body__content">
                    <div className="timeline__body__content__text">
                      <p>{renderHTML(t("indexPage.collapsables.mintText1"))}</p>
                      <p>{renderHTML(t("indexPage.collapsables.mintText2"))}</p>
                    </div>
                    <div className="timeline__body__content__img">
                      <img src="/img/icons/sidecard-marketplace.png" alt="" />
                    </div>
                  </div>
                </Collapsible>
                <Collapsible
                  open={true}
                  trigger={
                    <div className="timeline__body__section">
                      <img src="/img/icons/mint_white.png" alt="" />{" "}
                      {t("indexPage.collapsables.futureBotTitle")}
                    </div>
                  }
                >
                  <div className="timeline__body__content">
                    <div className="timeline__body__content__text">
                      <p>{renderHTML(t("indexPage.collapsables.futureBotText1"))}</p>
                      <p>{renderHTML(t("indexPage.collapsables.futureBotText2"))}</p>
                      <p>{renderHTML(t("indexPage.collapsables.futureBotText3"))}</p>
                    </div>
                    <div className="timeline__body__content__img">
                      <img src="/img/icons/sidecard-mint.png" alt="" />
                    </div>
                  </div>
                </Collapsible>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

function HoF() {
  return (
    <Suspense fallback={<Loader open={true} />}>
      <Index />
    </Suspense>
  );
}

export default HoF;
